const URL = "http://localhost:4000/";
const COMPANY_NAME = "مقصود اسلمی";
const COMPANY_TITLE = "پخش دارویی مقصود اسلمی";
const COMPANY_SHOP_ADDRESS =
  "آدرس: هرات، شهر نو، جاده عیدگاه، مارکت غزنوی، طبقه دوم، دوکان نمبر ۵۴-۱ ";
const COMPANY_PHONE = "۰۷۹۵۲۹۹۹۰۰";
const COMPANY_USERNAME = "مقصود اسلمی";
// system constant
const SYSTEM_PHONE = "0790883362";
const SYSTEM_TITLE = "سیستم مدیریتی اکسیجن";
const USERROLL = {
  ADMIN: "Admin",
  STANDARD: "Standard",
};
// province
const PROVINCES = [
  "هرات",
  "کابل",
  "مزار شریف",
  "فراه",
  "کندهار",
  "هلمند",
  "غزنی",
  "نیمروز",
  "ننگرهار",
  "بدخشان",
  "بادغیس",
  "بغلان",
  "بامیان",
  "دایکندی",
  "فاریاب",
  "غور",
  "جوزجان",
  "کاپیسا",
  "خوست",
  "کنر",
  "کندز",
  "لغمان",
  "لوگر",
  "نورستان",
  "ارزگان",
  "پکتیا",
  "پکتیکا",
  "پنجشیر",
  "پروان",
  "سمنگان",
  "سرپل",
  "تخار",
  "وردک",
  "زابل",
];
// Date
const WEEKDAYS = ["ش 1", "2ش", "3ش", "4ش", "5ش", "ج", "ش"]
const MONTHS = ["حمل", "ثور", "جوزا", "سرطان", "اسد", "سنبله", "میزان", "عقرب", "قوس", "جدی", "دلو", "حوت"]

module.exports = {
  URL,
  COMPANY_NAME,
  COMPANY_TITLE,
  COMPANY_SHOP_ADDRESS,
  COMPANY_PHONE,
  COMPANY_USERNAME,
  USERROLL,
  PROVINCES,
  SYSTEM_PHONE,
  SYSTEM_TITLE,
  WEEKDAYS,
  MONTHS
};
