import "../../Styles/StaticComponents/PrintHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLeftLong} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
export function PrintHeader(props) {
  const [profile,setProfile] = useState("")
  useEffect(()=>{
    const activeProfile = window.localStorage.getItem("profile");
    setProfile(JSON.parse(activeProfile)?.titleOne)
  },[])
  const navigate = useNavigate()
  const type = props.type;
  return (
    <div className="container">
      <div className="printHeader-container">
        <span className="type">{type}</span>
        <span className="name">{profile}</span>
        <img src="logo.png" alt="logo" className="print-logo" />
        <FontAwesomeIcon icon={faLeftLong} className="printHeader-icon" style={{ fontSize: 17,cursor:"pointer",position:"absolute",left:15 }}  onClick={() => navigate(-1)}/>
      </div>
    </div>
  );
}
