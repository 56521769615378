import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/Home/Home.css";
import "../../Styles/Settings/Settings.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Space, Tag, Modal, Button } from "antd";
import { UPDATEPROFILE,ADDPROFILE } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import { useApolloClient } from "@apollo/client";
import { PROFILE } from "../../GraphQL/Query";
import axios from 'axios';
import { FaCloudUploadAlt } from "react-icons/fa";

export function Settings() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname == "/settings") {
    location.pathname = "تنظیمات";
  }
  const [referesh, setReferesh] = useState(false);
  const [profile, setProfile] = useState()
  const [newProfile, setNewProfile] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const [alert, setAlert] = useState({
    alertStatus: "",
    action: "",
  });
  const [file, setFile] = useState();
  const [message, setMessage] = useState('');
  const [file2, setFile2] = useState();
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSrc2, setImageSrc2] = useState(null);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loadProfile()
  }, [referesh]);
  const loadProfile = async () => {
    try {
      const res = await client.query({
        query: PROFILE,
        variables: {},
        fetchPolicy: "no-cache",
      });
      if (res) {
        setProfile(res.data.getProfile);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function addProfile(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: ADDPROFILE,
        variables: {
          titleOne: e.target.titleOne.value,
          titleTwo: e.target.titleTwo.value,
          owners: [e.target.ownerOne.value, e.target.ownerTwo.value],
          phones: [e.target.phoneOne.value, e.target.phoneTwo.value, e.target.phoneThree.value, e.target.phoneFour.value, e.target.phoneFive.value, e.target.phoneSix.value],
          address: e.target.address.value
        },
      });
      if (res) {
        setReferesh(!referesh);
        setNewProfile(false)
        setAlert({
          action: "success",
          alertStatus: "add",
        });
        window.localStorage.setItem("profile", JSON.stringify(res.data.addProfile));
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }
  async function updateProfile(e) {
    e.preventDefault();
    try {
      const res = await client.mutate({
        mutation: UPDATEPROFILE,
        variables: {
          titleOne: e.target.titleOne.value,
          titleTwo: e.target.titleTwo.value,
          owners: [e.target.ownerOne.value, e.target.ownerTwo.value],
          phones: [e.target.phoneOne.value, e.target.phoneTwo.value, e.target.phoneThree.value, e.target.phoneFour.value, e.target.phoneFive.value, e.target.phoneSix.value],
          address: e.target.address.value
        },
      });
      if (res) {
        setReferesh(!referesh);
        setEditProfile(false)
        setAlert({
          action: "success",
          alertStatus: "edit",
        });
        window.localStorage.setItem("profile", JSON.stringify(res.data.editProfile));
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        action: "",
        alertStatus: "",
      });
    }
  }

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    };
    const handleSubmitLogo = async (event) => {
      event.preventDefault();
      if (!file) {
        setAlert({
          action: "empty",
          alertStatus: "edit",
        });
        // return;
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await axios.post('https://omid-backend.techlands.online/uploadLogo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data) {
          setMessage(response.data);
          setAlert({
            action: "success",
            alertStatus: "add",
          });
        }
        setTimeout(() => {
          setAlert({
            action: "",
            alertStatus: "",
          });
        }, 3000);
      } catch (error) {
        // setMessage('File upload failed.');
        console.error('There was an error uploading the file!', error);
        setAlert({
          action: "",
          alertStatus: "",
        });
      }
    };
    const handleFileChange2 = (event) => {
      const selectedFile2 = event.target.files[0]
      setFile2(selectedFile2);
      if (selectedFile2) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc2(reader.result);
        };
        reader.readAsDataURL(selectedFile2);
      }
    };
    const handleSubmitBarcode = async (event) => {
      event.preventDefault();
      if (!file2) {
        setAlert({
          action: "empty",
          alertStatus: "edit",
        });
        // return;
      }
      setTimeout(() => {
        setAlert({
          action: "",
          alertStatus: "",
        });
      }, 3000);
      const formData = new FormData();
      formData.append('image', file2);
  
      try {
        const response = await axios.post('https://omid-backend.techlands.online/uploadBarcode', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data) {
          setMessage(response.data);
          setAlert({
            action: "success",
            alertStatus: "add",
          });
        }
        setTimeout(() => {
          setAlert({
            action: "",
            alertStatus: "",
          });
        }, 3000);
      } catch (error) {
        // setMessage('File upload failed.');
        console.error('There was an error uploading the file!', error);
        setAlert({
          action: "",
          alertStatus: "",
        });
      }
    };
  return (
    <div className="container">
      <Header address={location.pathname} />
      <SideBar />
      <div className="content">
        {alert.action != "" && (
          <Alert action={alert.action} alertStatus={alert.alertStatus} />
        )}
        {newProfile && (
          <Modal
            title="افزودن مشخصات سیستم"
            open={newProfile}
            onCancel={() => {
              setNewProfile(!newProfile);
            }}
            footer={null}
            style={{ direction: "rtl" }}
            centered
          >
            <form onSubmit={addProfile}>
              <Space direction="vertical">
                <Input
                  id="titleOne"
                  name="titleOne"
                  size="large"
                  placeholder="سر برگ اول"
                  // defaultValue={profile?.titleOne}
                />
                <Input
                  id="titleTwo"
                  name="titleTwo"
                  size="large"
                  placeholder="سر برگ دوم"
                  // defaultValue={profile?.titleTwo}
                />
                <Input
                  id="ownerOne"
                  name="ownerOne"
                  size="large"
                  placeholder="ریس شرکت 1"
                  // defaultValue={profile?.owners[0]}

                />
                <Input
                  id="ownerTwo"
                  name="ownerTwo"
                  size="large"
                  placeholder="ریس شرکت 2"
                  // defaultValue={profile?.owners[1]}
                />
                <span className="phone-label">شماره های سیستم</span>
                <Space className="phone-container">
                  <Input
                    id="phoneOne"
                    name="phoneOne"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    // defaultValue={profile?.phones[0]}
                  />
                  <Input
                    id="phoneTwo"
                    name="phoneTwo"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    // defaultValue={profile?.phones[1]}
                  />
                  <Input
                    id="phoneThree"
                    name="phoneThree"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    // defaultValue={profile?.phones[2]}
                  />
                  <Input
                    id="phoneFour"
                    name="phoneFour"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    // defaultValue={profile?.phones[3]}
                  />
                  <Input
                    id="phoneFive"
                    name="phoneFive"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    // defaultValue={profile?.phones[4]}
                  />
                  <Input
                    id="phoneSix"
                    name="phoneSix"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    // defaultValue={profile?.phones[5]}
                  />
                </Space>
                <Input
                  id="address"
                  name="address"
                  size="large"
                  placeholder="آدرس شرکت"
                  // defaultValue={profile?.address}
                />
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<PlusOutlined />}
                    className="btn"
                  >
                    افزودن
                  </Button>
                </Space>
              </Space>
            </form>
          </Modal>
        )}
        {editProfile && (
          <Modal
            title="ویرایش مشخصات سیستم"
            open={editProfile}
            onCancel={() => {
              setEditProfile(!editProfile);
            }}
            footer={null}
            style={{ direction: "rtl" }}
            centered
          >
            <form onSubmit={updateProfile}>
              <Space direction="vertical">
                <Input
                  id="titleOne"
                  name="titleOne"
                  size="large"
                  placeholder="سر برگ اول"
                  defaultValue={profile?.titleOne}
                />
                <Input
                  id="titleTwo"
                  name="titleTwo"
                  size="large"
                  placeholder="سر برگ دوم"
                  defaultValue={profile?.titleTwo}
                />
                <Input
                  id="ownerOne"
                  name="ownerOne"
                  size="large"
                  placeholder="ریس شرکت 1"
                  defaultValue={profile?.owners[0]}

                />
                <Input
                  id="ownerTwo"
                  name="ownerTwo"
                  size="large"
                  placeholder="ریس شرکت 2"
                  defaultValue={profile?.owners[1]}
                />
                <span className="phone-label">شماره های سیستم</span>
                <Space className="phone-container">
                  <Input
                    id="phoneOne"
                    name="phoneOne"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    defaultValue={profile?.phones[0]}
                  />
                  <Input
                    id="phoneTwo"
                    name="phoneTwo"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    defaultValue={profile?.phones[1]}
                  />
                  <Input
                    id="phoneThree"
                    name="phoneThree"
                    size="large"
                    placeholder="شماره ریس 1"
                    className="phone-input"
                    defaultValue={profile?.phones[2]}
                  />
                  <Input
                    id="phoneFour"
                    name="phoneFour"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    defaultValue={profile?.phones[3]}
                  />
                  <Input
                    id="phoneFive"
                    name="phoneFive"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    defaultValue={profile?.phones[4]}
                  />
                  <Input
                    id="phoneSix"
                    name="phoneSix"
                    size="large"
                    placeholder="شماره ریس 2"
                    className="phone-input"
                    defaultValue={profile?.phones[5]}
                  />
                </Space>
                <Input
                  id="address"
                  name="address"
                  size="large"
                  placeholder="آدرس شرکت"
                  defaultValue={profile?.address}
                />
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<PlusOutlined />}
                    className="btn"
                  >
                    ویرایش
                  </Button>
                </Space>
              </Space>
            </form>
          </Modal>
        )}
        {!profile  ?
          <Button
          className="settings-btn"
          type="primary"
          htmlType="submit"
          size="large"
          icon={<EditOutlined />}
          onClick={() => setNewProfile(!newProfile)}
        >
          افزودن
        </Button>
        :
        <Button
          className="settings-btn"
          type="primary"
          htmlType="submit"
          size="large"
          icon={<EditOutlined />}
          onClick={() => setEditProfile(!editProfile)}
        >
          ویرایش
        </Button>}
        <Space className="settings-container">
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              سر برگ اول شرکت :
            </Tag>
            <Input placeholder="سر برگ اول شرکت" className="settings-input" value={profile?.titleOne} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              سر برگ دوم شرکت :
            </Tag>
            <Input placeholder="سر برگ دوم شرکت" className="settings-input" value={profile?.titleTwo} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              نام ریس شرکت 1
            </Tag>
            <Input placeholder="نام ریس های شرکت" className="settings-input" value={profile?.owners[0]} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              نام ریس شرکت 2
            </Tag>
            <Input placeholder="نام ریس های شرکت" className="settings-input" value={profile?.owners[1]} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              شماره های موبایل شرکت 1
            </Tag>
            <Input placeholder="شماره موبایل 1" className="settings-phone" value={profile?.phones[0]} />
            <Input placeholder="شماره موبایل 2" className="settings-phone" value={profile?.phones[1]} />
            <Input placeholder="شماره موبایل 3" className="settings-phone" value={profile?.phones[2]} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              شماره های موبایل شرکت 2
            </Tag>
            <Input placeholder="شماره موبایل 1" className="settings-phone" value={profile?.phones[3]} />
            <Input placeholder="شماره موبایل 2" className="settings-phone" value={profile?.phones[4]} />
            <Input placeholder="شماره موبایل 3" className="settings-phone" value={profile?.phones[5]} />
          </Space>
          <Space>
            <Tag bordered={false} color="success" className="settings-labbel">
              آدرس شرکت :
            </Tag>
            <Input placeholder="آدرس شرکت" className="settings-input" value={profile?.address} />
          </Space>
          <Space className="profileImage-container">
            <form onSubmit={handleSubmitLogo}>
              <label htmlFor="uploadLogo" className="profileImage-label">
              <span className="profileImage">
              <input type="file" accept="image/*" className="profileImage-input" onChange={handleFileChange}  id="uploadLogo"/>
              {imageSrc ? <img src={imageSrc} alt="Selected"/> : <img src={`https://omid-backend.techlands.online/images/${profile?.logo}` && "upload.png"} alt="logo"/>}
              </span>
              </label>
              {/* <button type="submit" className="profileImage-btn">Upload Logo</button> */}
              <Button
                className="profileImage-btn"
                type="primary"
                htmlType="submit"
                size="large"
                icon={<FaCloudUploadAlt />}
                // onClick={() => setNewProfile(!newProfile)}
              >
                آپلود لوگو
              </Button>
            </form>
            <form onSubmit={handleSubmitBarcode}>
              <label htmlFor="uploadBarcode" className="profileImage-label">
              <span className="profileImage">
              <input type="file" accept="image/*" className="profileImage-input" onChange={handleFileChange2}  id="uploadBarcode"/>
              {imageSrc2 ? <img src={imageSrc2} alt="Selected"/> : <img src={`https://omid-backend.techlands.online/images/${profile?.barcode}` && "upload.png"} alt="Selected"/>}
              </span>
              </label>
              {/* <button type="submit" className="profileImage-btn">Upload Logo</button> */}
              <Button
                className="profileImage-btn"
                type="primary"
                htmlType="submit"
                size="large"
                icon={<FaCloudUploadAlt />}
                // onClick={() => setNewProfile(!newProfile)}
              >
                آپلود لوگو
              </Button>
            </form>
          </Space>
          <Space>
          </Space>
        </Space>
      </div>
    </div>
  );
}
